/**
 * Tooltip
 * Looks in all rich text sections and binds tippy plugin to the tooltip class.
 * TODO: This along with responsive tables should be refactored to live in UIKit
 * and only load when rich text is used.
 */

const TOOLTIP_SELECTOR = ".rich-text .tooltip";
const TOOLTIP_CONTENT_SELECTOR = ".tooltip-content";
const TOOLTIP_TRIGGER_CLASS_LIST = ["underline", "font-bold", "cursor-pointer"];


const tooltips = document.querySelectorAll(TOOLTIP_SELECTOR);
const hasTooltips = () => tooltips.length > 0;

const bindTippy = ({ el, tippy }) => {
  // Define DOM elements
  const trigger = el;
  const content = el.querySelector(TOOLTIP_CONTENT_SELECTOR);

  // Bind decodation classes to trigger
  trigger.classList.add(...TOOLTIP_TRIGGER_CLASS_LIST);

  // Init tippy
  tippy(trigger, {
    content: content,
    allowHTML: true,
    theme: "custom",
    trigger: "mouseenter click",
  });
};

const initTooltips = () => {
  import("tippy.js").then(({ default: tippy }) => {
    tooltips.forEach((el) => {
      bindTippy({ el, tippy });
    });
  });
};

if (hasTooltips()) {
  initTooltips();
}
