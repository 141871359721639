import Alpine from "alpinejs";
import AsyncAlpine from "async-alpine";
import mask from "@alpinejs/mask";
import intersect from "@alpinejs/intersect";
import persist from "@alpinejs/persist";
import { tns } from "tiny-slider/src/tiny-slider";

import "./magics";
import "./directives";

import "./modules/lazysizes";
import "./modules/responsiveTables";
import "./modules/tooltip/tooltip";
import "./modules/track";

window.tns = tns;
window.Alpine = Alpine;
Alpine.plugin(intersect);
Alpine.plugin(mask);
Alpine.plugin(persist);

document.addEventListener("alpine:initialized", () => {
  window.Alpine.isInitialized = true;
});

export { Alpine, AsyncAlpine };
