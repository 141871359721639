import { Alpine, AsyncAlpine } from "./main.alpinejs.js";
// Store variables
import "./stores/sectionIdStore.js";
import "./stores/oneTrustStore.js";

AsyncAlpine.init(Alpine);

// Register components we will lazy load
// TODO: Investigate https://async-alpine.dev/docs/usage/#alias
// Possibly we can do this without needing to register them in here but we would need to first compile them via glob in rollup
// Global
AsyncAlpine.data("app", () => import("./x-data/app/index.js"));
AsyncAlpine.data("dateTime", () => import("./x-data/dateTime.js"));
AsyncAlpine.data("eventDetails", () => import("./x-data/eventDetails/eventDetails.js"));
AsyncAlpine.data("eventListing", () => import("./x-data/list/eventListing.js"));
AsyncAlpine.data("formComponent", () => import("./x-data/formComponent/formComponent.js"));
AsyncAlpine.data("listingFilters", () => import("./x-data/list/listingFilters.js"));
AsyncAlpine.data("locator", () => import("./x-data/locator/index.js"));
AsyncAlpine.data("isi", () => import("./x-data/isi/index.js"));
AsyncAlpine.data("modalScripts", () => import("./x-data/modal/modalScripts.js"));
AsyncAlpine.data("orderCenter", () => import("./x-data/orderCenter/orderCenter.js"));
AsyncAlpine.data("quiz", () => import("./x-data/quiz/quiz.js"));
AsyncAlpine.data("skipToMain", () => import("./x-data/skipToMain/skipToMainScript.js"));
AsyncAlpine.data("pageMenuLink", () => import("./x-data/pageMenuLink/pageMenuLink.js"));
AsyncAlpine.data("glossary", () => import("./x-data/glossary/glossary.js"));
AsyncAlpine.data("talentLmsButton", () => import("./x-data/talentLmsButton/talentLmsButton.js"));
AsyncAlpine.data("loginPicker", () => import("./x-data/loginPicker/loginPicker.js"));
AsyncAlpine.data("docCheck", () => import("./x-data/docCheck/docCheck.js"));
AsyncAlpine.data("navSearchPanel", () => import("./x-data/navSearchPanel/navSearchPanel.js"));
AsyncAlpine.data("video", () => import("./x-data/video/video.js"));
AsyncAlpine.data("mediaPlaylist", () => import("./x-data/media/mediaPlaylist.js"));
AsyncAlpine.data("paywall", () => import("./x-data/paywall/paywall.js"));

// Auth
AsyncAlpine.data("changePassword", () => import("./x-data/auth/changePassword.js"));
AsyncAlpine.data("consentAcknowledge", () => import("./x-data/auth/consentAcknowledge.js"));
AsyncAlpine.data("editProfile", () => import("./x-data/auth/editProfile.js"));
AsyncAlpine.data("emailVerification", () => import("./x-data/auth/emailVerification.js"));
AsyncAlpine.data("forgotPassword", () => import("./x-data/auth/forgotPassword.js"));
AsyncAlpine.data("incompleteProfile", () => import("./x-data/auth/incompleteProfile.js"));
AsyncAlpine.data("login", () => import("./x-data/auth/login.js"));
AsyncAlpine.data("loginNoAuth", () => import("./x-data/auth/loginNoAuth.js"));
AsyncAlpine.data("profile", () => import("./x-data/auth/profile.js"));
AsyncAlpine.data("registration", () => import("./x-data/auth/registration.js"));
AsyncAlpine.data("resetPassword", () => import("./x-data/auth/resetPassword.js"));
AsyncAlpine.data("unsubscribe", () => import("./x-data/auth/unsubscribe.js"));
AsyncAlpine.data("newsletter", () => import("./x-data/auth/newsletter.js"));
